<template>
  <div class="about">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <!-- <h2 class="h2 margin">DGFM</h2> -->
          <!-- <h5 class="h5">
            Dirección General de Formación de Maestros
          </h5> -->
          <p class="text">
            <b>Objetivo: </b>Promover e implementar políticas de formación de
            maestras y maestros y la cualificación de los recursos humanos del
            Sistema Educativo Plurinacional, en concordancia con los preceptos
            constitucionales y la Ley N° 070 de la Educación "Avelino Siñani -
            Elizardo Pérez"
          </p>
        </div>
      </div>
    </div>
    <!-- <div>
      <div class="container">
        <div class="breadcrumbs">
          <router-link class="link breadcrumb" to="/">Inicio</router-link>
          <div class="slash">/</div>
          <div class="breadcrumb-text">Acerca de</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="section">
      <div class="container">
        <div class="post-contents">
          <h3 class="h3 not-margin">
            EQUIPO DE TRABAJO
          </h3>
          <p class="small-text">
            <ul>
              <li>Equipo de Formación Inicial</li>
              <li>Equipo de Formación Continua y Postgradual</li>
              <li>Equipo de Gestión Institucional</li>
            </ul>
          </p>
        </div>
      </div>
    </div> -->
    <div class="section">
      <div class="container">
        <div class="two-col-grid">
          <div class="small-container">
            <h3 class="h3">Equipo de Formación Inicial y Formación Continua y Postgradual</h3>
            <p>
              <ul style="padding-left: 15px">
                <li>Consolidación de la estructura de formación de maestras y maestros con calidad de pertinencia en base al modelo educativo sociocomunitario productivo y las políticas educativas del Estado Plurinacional de Bolivia.</li>
                <li>Elaboración del currículo de formación de maestras y maestros, en el marco de los principios de educación descolonizadora, comunitaria, productiva, intra-intercultural plurilingüe, científica, tecnológica y artística.</li>
                <li>Elaboración de planes y programas por modalidad, niveles y especialidades.</li>
                <li>Socialización y validación de documentos curriculares con las Escuelas Superiores de Formación de Maestros, Universidad Pedagógica, UNEFCO, magisterio nacional, organizaciones sociales, comunidades interculturales, sociedad civil organizada y todos los actores involucrados en la formación de maestras y maestros, a través de encuentros y otros eventos para alcanzar consensos.</li>
                <li>Aplicación de currículo de formación de maestras y maestros, de planes y de programas curriculares en los ámbitos establecidos.</li>
                <li>Seguimiento y monitoreo permanente a la implementación y aplicación de los planes, programas curriculares y normativa de carácter académico-curricular de la estructura de formación de maestras y maestros del SEP, con base a acciones de coordinación intra e inter sectorial.</li>
                <li>Fortalecimiento del personal directivo, docente y administrativo de la estructura de formación de maestras y maestros mediante acciones de apoyo, orientación y capacitación en temas académico-curriculares.</li>
              </ul>
            </p>
          </div>
          <img src="images/team_mision.svg" loading="lazy" alt="mision" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="two-col-grid">
          <img src="images/team_vision.svg" loading="lazy" alt="vision" />
          <div
            id="w-node-_44797ee6-5486-1d8f-0733-0be36c3ba4ca-3b1c0e06"
            class="small-container hero"
          >
            <h3 class="h3">Equipo de Gestión Institucional</h3>
            <p>
              <ul style="padding-left: 15px">
                <li>Formulación de Normas y Reglamentos de organización administrativa y de personal para el funcionamiento de la estructura de formación de maestras y maestros.</li>
                <li>Formulación de Normas y Reglamentos de Organización Curricular para el funcionamiento de la estructura de formación de maestras y maestros.</li>
                <li>Capacitación y actualización del personal de la estructura de formación de maestras y maestros en aspectos institucionales.</li>
                <li>Evaluación del desempeño del personal de la estructura de formación de maestras y maestros.</li>
                <li>Institucionalización de cargos directivos, docentes y administrativos de las ESFM/UA.</li>
                <li>Desarrollo del Proceso de Admisión de postulantes a las ESFM y UA acorde a las demandas de los Subsistemas de Educación Regular y Alternativa.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section">
      <div class="container">
        <div class="title-container">
          <h3 class="h3">Personal Directivo</h3>
        </div>
        <div class="team-grid">
          <div
            id="w-node-_37a01fb2-3b19-cbcd-1145-d42cd3b984e8-3b1c0e06"
            class="team-member"
          >
            <div class="testimonial-details-v-3">
              <div class="large-avatar"></div>
              <div class="h5">Rufino Sacaca Sánchez</div>
              <div class="small-text">
                Director General de Formación de Maestros
              </div>
            </div>
          </div>
          <div
            id="w-node-_37a01fb2-3b19-cbcd-1145-d42cd3b984f8-3b1c0e06"
            class="team-member"
          >
            <div class="testimonial-details-v-3">
              <div class="large-avatar avatar-2"></div>
              <div class="h5">Luz Gabriela Perez Gutierrez</div>
              <div class="small-text">
                Responsable de Gestión Institucional
              </div>
            </div>
          </div>
          <div
            id="w-node-_37a01fb2-3b19-cbcd-1145-d42cd3b98508-3b1c0e06"
            class="team-member"
          >
            <div class="testimonial-details-v-3">
              <div class="large-avatar avatar-2"></div>
              <div class="h5">Maria Elena Martinez Pardo</div>
              <div class="small-text">Responsable de Formación Inicial</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  export default {
    name: "about",
  };
</script>

<style scoped></style>
